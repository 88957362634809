module.exports = {
    config: {
        merchantId: 4,
        merchantShopName: "BlueOrigin",
        merchantDomain: "https://laspiruline.fr/",
        defaultMetaImage: "assets/images/meta-shop-picture.png",
        enabledLanguagues: ['fr'],
        defaultLanguage: 'fr',
        enabledCurrencies: ["EUR"],
        defaultCurrency: "EUR",
        enabledDeliveryCountry: ['fr', 'ch', 'mc', 'de', 'be', 'lu', 'nl', 'it', 'es', 'at', 'pt', 'ie', 'dk', 'ee', 'hu',
            'lv', 'lt', 'pl', 'cz', 'sk', 'si', 'se', 'bg', 'cy', 'hr', 'fi', 'gr', 'mt', 'ro'],
        nbProductsByPage: 24,
        shopEmail: "contact@laspiruline.fr",
        shopPhone: "+33 (0) 6 33 61 18 61",
        stripeCountryAccount: 'FR',
        shopAddress: "Chemin de la Plaine de Laval",
        shopPostalCity: "06150 Cannes",
        shopCountry: "France",
        shopCountryCode: "FR",
        shopSlogan: {
            "fr": "La spiruline artisanale de Cannes.",
            "en": "Fast and efficient online sales."
        },
        shopMapsUrl: "",
        socialMedias: {
            facebook: "https://www.facebook.com/BlueOrigin-la-spiruline-104793109133198",
            instagram: "https://www.instagram.com/blueoriginlaspiruline"
        },
        footerLink: [
            {
                "name": "Livraison",
                "url": "/delivery"
            },
            {
                "name": "Foire Aux Questions (FAQ)",
                "url": "/faq"
            },
            {
                "name": "FOOTER_LNOTICE",
                "url": "/legal-notice"
            },
            {
                "name": "FOOTER_TERMS",
                "url": "/terms-of-sales"
            },
        ],
        menu: [
            {
                "name": "NAV_HOME",
                "path": "home",
                "submenu": []
            },
            {
                "name": "La spiruline",
                "path": "la-spiruline",
                "submenu": []
            },
            {
                "name": "Notre ferme",
                "path": "notre-ferme",
                "submenu": []
            },
            {
                "name": "Boutique",
                "path": "catalog",
                "submenu": []
            },
            {
                "name": "Analyses & docs",
                "path": "https://drive.google.com/drive/folders/1BrqfyfmyBnyFSKUEgKQiPGr7_vv1VO1k?usp=drive_link",
                "external": true,
                "submenu": []
            },
            {
                "name": "F.A.Q.",
                "path": "faq",
                "submenu": []
            },
            {
                "name": "Contact",
                "path": "contact",
                "submenu": []
            }
        ],
        mondialRelayCode: "CC22ILF0",
        mondialRelayColLivMod: "24R",
        stripeKey: "pk_live_51IDX9sFONvuSNpV2o5KatJP8qQncNXdbw5DfzLuVwEoRk6dyyxdC0U3WasFIWMO25RidY1DF6TO9HncLY1YMyDrl00vpUhDuJU",
        stripeAccount: "acct_1M9RrEFaLbEbu7He",
        quoteDownloadEnabled: false,
        reviewEnabled: true,
        defaultCatalogSorting: 'RELEVANCE',
        enabledB2B: false,
        redirect: [],
        emptyBoxId: 399, //DEPRECATED
        otherCatId: 22,
        productBoxMapping: [
            {withBox: 376, without: 388},
            {withBox: 394, without: 382},
            {withBox: 395, without: 383},
            {withBox: 375, without: 390},
            {withBox: 392, without: 380},
            {withBox: 396, without: 381},
            {withBox: 374, without: 389},
            {withBox: 378, without: 391},
            {withBox: 379, without: 393},
        ]
    }
}
